import type { LinksData } from '@jouzen/ecom-components';
import type {
  Formats,
  RichTranslationValues,
  TranslationValues,
} from 'next-intl';
import type { ReactElement } from 'react';

import type { StsgLink } from '../hooks/useLinkModifierConfig';

export interface NextT {
  <TargetKey>(
    key: TargetKey,
    values?: TranslationValues,
    formats?: Partial<Formats>,
  ): string;
  rich<TargetKey>(
    key: TargetKey,
    values?: RichTranslationValues,
    formats?: Partial<Formats>,
  ): string | ReactElement;
}

const localizeLinks = (
  statsigLinks: LinksData[] | undefined,
  t: NextT,
  tValues = {},
) => {
  if (statsigLinks) {
    for (const links of statsigLinks) {
      if (links.links) {
        for (const link of links.links) {
          link.label = t.rich(link.label, tValues);
        }
      }
      if (links.link) {
        links.link.label = t.rich(links.link.label);
        if (links.link.overlay) {
          links.link.overlay.title = t.rich(links.link.overlay.title, tValues);
          if (links.link.overlay.description) {
            links.link.overlay.description = t.rich(
              links.link.overlay.description,
              tValues,
            );
          }
        }
      }
      links.title = t.rich(links.title, tValues);
    }
  }
};

export const localizeMenuImageLink = (
  statsigLink: StsgLink | undefined,
  t: NextT,
  tValues = {},
): void => {
  if (!statsigLink) return;

  if (statsigLink.label) {
    statsigLink.label = t.rich(statsigLink.label, tValues);
  }
  if (statsigLink.link) {
    statsigLink.link.label = t.rich(statsigLink.link.label, tValues);
    if (statsigLink.link.overlay) {
      statsigLink.link.overlay.title = t.rich(
        statsigLink.link.overlay.title,
        tValues,
      );
      if (statsigLink.link.overlay.description) {
        statsigLink.link.overlay.description = t.rich(
          statsigLink.link.overlay.description,
          tValues,
        );
      }
    }
  }
};

export default localizeLinks;
