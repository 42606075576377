import ArtDirectionImage from '../Image/ArtDirectionImage';

export const FooterImageComponent = (): JSX.Element => {
  return (
    <ArtDirectionImage
      className="h-56 w-full object-cover md:h-full md:w-auto xxl:min-w-full"
      fill
      localizedAlt="shop_rings"
      sizes="(max-width: 767px) 100vw, 25vw"
      srcSet={[
        {
          src: 'blue-sky/footer/buesiness-footer-cta-mobile',
          media: '(max-width: 767px)',
        },
        {
          src: 'blue-sky/footer/business-footer-cta',
          media: '(min-width: 768px)',
        },
      ]}
    />
  );
};
