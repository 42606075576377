import { useTranslations } from 'next-intl';

import useIsBFCM from '@/app/hooks/useIsBFCM';

import Image from '../Image';

export const MenuImageComponent = (): JSX.Element => {
  const t = useTranslations();
  const isBFCM = useIsBFCM();

  return (
    <Image
      alt={t('shop_rings')}
      className="h-36 rounded-lg object-cover md:h-auto"
      height={233}
      src={isBFCM ? 'blue-sky/gen3-menu-image.jpg' : 'Mask_BXZVD9.png'}
      width={400}
    />
  );
};
