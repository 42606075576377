import resolveConfig from 'tailwindcss/resolveConfig';

// eslint-disable-next-line no-restricted-imports
import tailwindConfig from '../../../tailwind.config';
import type { TailwindThemeProperty } from '../types/tailwind';

const { theme } = resolveConfig(tailwindConfig);

const useTailwindThemeValue = (
  themeProperty: TailwindThemeProperty,
  value: string,
): string | null | undefined => {
  if (!value) return null;
  if (themeProperty === 'colors') {
    const [color, shade = 'DEFAULT'] = value.split('-');
    const colors = theme.colors as any;
    return colors[color!]?.[shade] || colors[color!];
  } else {
    return (theme as any)[themeProperty]?.[value];
  }
};

export default useTailwindThemeValue;
