import { GEN3_POP_PATHNAME, POP_PATHNAME } from '@/app/consts/pathnames';

const isPathMatchToPOP = (pathname: string) => {
  const localizedPathname = new RegExp(`^(/..)?${GEN3_POP_PATHNAME}$`);
  const isGen3POP = localizedPathname.test(pathname);
  const currentPOPPath: string = isGen3POP ? GEN3_POP_PATHNAME : POP_PATHNAME;
  const localizedPOPPathname = new RegExp(`^(/..)?${currentPOPPath}$`);

  return { isGen3POP, isPOP: localizedPOPPathname.test(pathname) };
};

export default isPathMatchToPOP;
