import {
  type HeaderV3Props,
  LinksCategoryId,
  type LinksData,
} from '@jouzen/ecom-components';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslations } from 'next-intl';
import { type DynamicConfig, useConfig } from 'statsig-react';

import useBlackFriday2024Pricing from '@/app/hooks/useBlackFriday2024Pricing';
import useIsBFCM from '@/app/hooks/useIsBFCM';

import isPathMatchToPOP from '../utils/isPathMatchToPOP';
import type { NextT } from '../utils/localizeLinks';
import localizeLinks, { localizeMenuImageLink } from '../utils/localizeLinks';
import tEmBlockValue from '../utils/tEmBlockValue';
import tSupValue from '../utils/tSupValue';
import useLinkModifierConfig from './useLinkModifierConfig';

interface MenuProps extends Omit<DynamicConfig, 'value'> {
  value: {
    menuLinks: HeaderV3Props['menuLinks'];
  };
}

interface ModifyPOPLinksProps {
  activeMenuLinkConfig: boolean;
  statsigLinks: HeaderV3Props['menuLinks'];
  isGen3POP: boolean;
  isPOP: boolean;
  selectedMenuLink: unknown;
}

interface ModifyPOPImageLinkProps
  extends Omit<
    ModifyPOPLinksProps,
    'activeMenuLinkConfig' | 'selectedMenuLink'
  > {
  activeMenuImageConfig: boolean;
  isBFCM: boolean;
  selectedMenuImageLink: unknown;
}

const modifyPOPLinks = ({
  activeMenuLinkConfig,
  statsigLinks,
  isGen3POP,
  isPOP,
  selectedMenuLink,
}: ModifyPOPLinksProps) => {
  if (activeMenuLinkConfig && statsigLinks && isPOP) {
    const shopCatIndex = statsigLinks.findIndex(
      ({ id }) => id === LinksCategoryId.ShopCategory,
    );
    if (shopCatIndex && shopCatIndex !== -1) {
      const links = statsigLinks[shopCatIndex]?.links;
      const targetId = isGen3POP ? 'sale' : 'oura-ring';
      const _selectedMenuLink = selectedMenuLink as LinksData['link'];

      const linksIndex = links?.findIndex(({ id }) => id === targetId);
      if (
        links &&
        typeof linksIndex !== 'undefined' &&
        linksIndex !== -1 &&
        _selectedMenuLink
      ) {
        links.splice(linksIndex, 1, _selectedMenuLink);
      }
    }
  }
};

const modifyPOPImageLink = ({
  activeMenuImageConfig,
  statsigLinks,
  isBFCM,
  isGen3POP,
  isPOP,
  selectedMenuImageLink,
}: ModifyPOPImageLinkProps) => {
  const shouldModify = isBFCM ? isPOP && isGen3POP : isPOP && !isGen3POP;
  if (activeMenuImageConfig && statsigLinks && shouldModify) {
    const imageIndex = statsigLinks.findIndex(
      ({ id }) => id === LinksCategoryId.MenuCard,
    );

    if (typeof imageIndex !== 'undefined' && imageIndex !== -1) {
      const _selectedMenuImageLink = selectedMenuImageLink as LinksData;
      statsigLinks.splice(imageIndex, 1, _selectedMenuImageLink);
    }
  }
};

const useMenuConfig = (pathname: string): HeaderV3Props['menuLinks'] => {
  const t = useTranslations();
  const isBFCM = useIsBFCM();
  const { greatestDiscount } = useBlackFriday2024Pricing();
  // default menu config
  const { config } = useConfig('global_menu_links');
  const activeMenuConfig = Object.keys(config).length > 0;
  const menuConfigValue = config.value as MenuProps['value'];

  // determine if current path is gen3 or OR4 POP, or neither
  const { isGen3POP, isPOP } = isPathMatchToPOP(pathname);

  // menu link modifier config for POP
  const { isConfigActive: activeMenuLinkConfig, statsigLink } =
    useLinkModifierConfig('pop_modified_menu_link', isGen3POP);
  const selectedMenuLink = cloneDeep(statsigLink);
  if (selectedMenuLink?.label) {
    selectedMenuLink.label = t.rich(
      selectedMenuLink.label,
      tSupValue,
    ) as JSX.Element;
  }

  // menu image modifier config for POP
  const {
    isConfigActive: activeMenuImageConfig,
    statsigLink: imageStatsigLink,
  } = useLinkModifierConfig(
    'pop_modified_menu_image_link',
    isGen3POP || isBFCM,
  );
  const selectedMenuImageLink = cloneDeep(imageStatsigLink);
  const bfcmTValues = { ...tSupValue, amount: greatestDiscount };
  const defaultTValues = {
    ...tSupValue,
    ...tEmBlockValue,
    amount: greatestDiscount,
  };
  localizeMenuImageLink(
    selectedMenuImageLink,
    t as NextT,
    isBFCM ? bfcmTValues : defaultTValues,
  );

  if (!activeMenuConfig) {
    return undefined;
  }

  const statsigLinks = cloneDeep(menuConfigValue.menuLinks);

  // translate statsig link fields
  localizeLinks(
    statsigLinks,
    t as NextT,
    isBFCM ? bfcmTValues : defaultTValues,
  );

  modifyPOPLinks({
    activeMenuLinkConfig,
    statsigLinks,
    isGen3POP,
    isPOP,
    selectedMenuLink,
  });

  modifyPOPImageLink({
    statsigLinks,
    activeMenuImageConfig,
    isBFCM,
    isGen3POP,
    isPOP,
    selectedMenuImageLink,
  });

  return statsigLinks;
};

export default useMenuConfig;
