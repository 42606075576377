import type { ReactNode } from 'react';

const tSupValue = {
  sup: (chunks: ReactNode) => (
    <sup className="font-serif font-semibold italic tracking-wider text-blue-200">
      {chunks}
    </sup>
  ),
};

export default tSupValue;
