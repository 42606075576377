import {
  type EcomFooterProps,
  LinksCategoryId,
  type LinksData,
} from '@jouzen/ecom-components';
import { cloneDeep } from 'lodash';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import { type DynamicConfig, useConfig } from 'statsig-react';

import type { NextT } from '../utils/localizeLinks';
import localizeLinks from '../utils/localizeLinks';

interface FooterLinkProps extends Omit<DynamicConfig, 'value'> {
  value: {
    footerLinks: EcomFooterProps['footerLinks'];
    legalLinks: EcomFooterProps['legalLinks'];
  };
}

const useFooterConfig = (): FooterLinkProps['value'] | undefined => {
  const t = useTranslations();
  const { config } = useConfig('global_footer_links');
  const footerConfigValue = config.value as FooterLinkProps['value'];
  const activeFooterConfig = Object.keys(footerConfigValue).length > 0;

  const statsigLinks = cloneDeep(footerConfigValue);

  if (statsigLinks.footerLinks) {
    treatFooterLinks(statsigLinks.footerLinks, t);
  }

  statsigLinks.legalLinks = statsigLinks.legalLinks?.map((link) => ({
    ...link,
    label: t(link.label),
  }));

  if (!activeFooterConfig) {
    return undefined;
  }

  return statsigLinks;
};

function treatFooterLinks(
  footerLinks: LinksData[],
  t: ReturnType<typeof useTranslations>,
) {
  const footerCardIndex = footerLinks.findIndex(
    (card) => card.id === LinksCategoryId.FooterCard,
  );

  let footerCard: LinksData[] | undefined;

  if (footerCardIndex >= 0) {
    footerCard = footerLinks.splice(footerCardIndex, 1);
  }

  // translate statsig link fields
  localizeLinks(footerLinks, t as NextT);

  if (footerCard) {
    // translate footer card fields
    localizeLinks(footerCard, t as NextT, {
      em: (chunks: ReactNode) => (
        <span className="self-end font-serif italic">{chunks}</span>
      ),
    });

    // place footer card back into the array
    footerLinks.splice(footerCardIndex, 0, ...footerCard);
  }
}

export default useFooterConfig;
